/* eslint-disable func-names, space-before-function-paren */
import inView from '@modules/inView';

// Load swiper styles with dynamic import so will not be imported in the same entry point twice
const getCarouselStyles = () => {
  import('swiper/css');
  import('swiper/css/pagination');
};

export default function init() {
  const benefitsCarousel = document.querySelectorAll(
    '.js-benefits-carousel'
  );
  inView(benefitsCarousel, async () => {
    getCarouselStyles();
    const { default: Swiper, Pagination } = await import('swiper');
    const desktopMedia = window.matchMedia('only screen and (min-width: 64rem)');
    benefitsCarousel.forEach(($el) => {
      let swiper = null;

      function addSwiperClasses() {
        $el.querySelectorAll('.js-swiper').forEach(($slide) => {
          $slide.classList.add('swiper');
        });
        $el.querySelectorAll('.js-swiper-wrapper').forEach(($slide) => {
          $slide.classList.add('swiper-wrapper');
        });
        $el.querySelectorAll('.js-swiper-slide').forEach(($slide) => {
          $slide.classList.add('swiper-slide');
        });
      }
      function removeSwiperClasses() {
        $el.querySelectorAll('.js-swiper').forEach(($slide) => {
          $slide.classList.remove('swiper');
        });
        $el.querySelectorAll('.js-swiper-wrapper').forEach(($slide) => {
          $slide.classList.remove('swiper-wrapper');
        });
        $el.querySelectorAll('.js-swiper-slide').forEach(($slide) => {
          $slide.classList.remove('swiper-slide');
        });
      }

      // Init swiper on mobile, clean up on desktop
      function initSwiper() {
        if (desktopMedia.matches) {
          if (swiper) {
            swiper.destroy();
            removeSwiperClasses();
            swiper = null;
          }
        } else if (!swiper) {
          addSwiperClasses();
          swiper = new Swiper($el.querySelector('.swiper'), {
            modules: [Pagination],
            slidesPerView: 1,
            pagination: {
              el: $el.querySelector('.swiper-pagination'),
              clickable: true,
            },
          });
        }
      }

      initSwiper();
      desktopMedia.addEventListener ? desktopMedia.addEventListener('change', initSwiper) : desktopMedia.addListener(initSwiper);
    });
  });
}
